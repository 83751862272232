<script setup lang="ts">
import {
	Drawer,
	DrawerContent,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
	DrawerClose,
	Dialog,
	DialogTrigger,
	DialogContent,
	useToast,
	DialogHeader,
} from '@laam/ui/base';

import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import { wishlistSchema } from './utils';
import WishlistForm from './WishlistForm.vue';
import { createReusableTemplate } from '@vueuse/core';
import { useCreateWishlist } from '~/data/wishlist';

const [UseTemplate, CreateWishlistForm] = createReusableTemplate();

const { handleSubmit } = useForm({
	validationSchema: toTypedSchema(wishlistSchema),
});

const { $eventClient } = useNuxtApp();

const LoganStore = useLoganStore();
const { createWishlist, isSuccess, isPending } = useCreateWishlist();
const { toast } = useToast();

const onSubmit = handleSubmit((values) => {
	createWishlist({
		name: values.name,
		allow_gifting: false,
		is_public: values.public,
	});

	$eventClient.sendEvent('wishlist-create', {
		loganID: LoganStore.loganId,
		wishlistID: -1,
	});

	open.value = false;
});

watch(isSuccess, (success) => {
	if (success) {
		open.value = false;
		toast({
			title: 'Wishlist created successfully',
		});
	}
});

const open = ref(false);
</script>
<template>
	<UseTemplate>
		<form class="pt-8xl p-3xl space-y-3xl" @submit.prevent="onSubmit">
			<WishlistForm action-title="Create" :action-loading="isPending" />
		</form>
	</UseTemplate>
	<Drawer v-if="isMobileView()" v-model:open="open">
		<DrawerTrigger as-child>
			<slot />
		</DrawerTrigger>
		<DrawerContent class="h-fit create_wishlist_drawer">
			<DrawerClose />
			<DrawerHeader>
				<DrawerTitle class="text-md font-semibold text-gray-800">
					New list
				</DrawerTitle>
			</DrawerHeader>
			<CreateWishlistForm />
		</DrawerContent>
	</Drawer>
	<Dialog v-else v-model:open="open">
		<DialogTrigger as-child>
			<slot />
		</DialogTrigger>
		<DialogContent class="create_wishlist_dialog min-w-[460px]">
			<DialogHeader>
				<DialogTitle class="text-md font-semibold text-gray-800">
					New list
				</DialogTitle>
			</DialogHeader>
			<CreateWishlistForm />
		</DialogContent>
	</Dialog>
</template>
